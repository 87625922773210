<template>
  <div>
    <b-table-simple>
      <tr v-for="(option, index) in selected" :key="index">
        <td>
          <strong>{{ option.Value }}</strong>
          <em class="option-description">{{ option.Description }}</em>

        </td>
        <td class="action-icons"><b-icon-x @click="remove(index)" width="30" /></td>
      </tr>
    </b-table-simple>

    <div v-if="selected.length == 0" class="mt-4 mb-4"><strong>No territories selected</strong></div>

    <c-select
      @input="onInput"
      :options="cOptions"
      placeholder=" "
      />
    
    <b-button class="mt-2" @click="add">Add</b-button>
  </div>
</template>

<script>
  export default {
    name: 'MultiSelectList',
    components: {
    },
    model: {
      prop: 'selected'
    },
    data () {
      return {
        newValue: null,
        
      }
    },
    props: {
      selected: Array,
      options: Array,
    },
    computed: {
      cOptions() {
        if (!this.options) return this.options
        return this.options.map(o => {
          return {value: o.JsonValue, label: o.Value}
        }) 
      }
    },
    methods: {
      onInput(val) {
        if (val.target) val = val.target.value
        this.newValue = val
      },
      add () {
        if (this.newValue == null) return
        let exists = false
        this.selected.forEach(o => {
          if (o.JsonValue == this.newValue) exists = true
        })

        if (!exists) {
          let territory = this.options.find(o => { return o.JsonValue == this.newValue })
          this.selected.push(territory)
        }
        this.newValue = null
      },
      remove (index) {
        this.selected.splice(index, 1)
      }
    },
    watch: {
      selected () {
        this.$emit('input', this.selected)
      }
    },
    mounted () {
    }
  }
</script>

<style lang="scss" scoped>
  em.option-description {
    display:block;
    margin-top: 5px;
  }
</style>